<template>
  <a-drawer class="honor-add" title="添加荣誉" @close="onClose" :width="720" :visible="honorAddVisiable" :maskClosable="false">

    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="奖项名称" prop="prizeName" v-bind="formItemLayout" >
        <a-input v-model="form.prizeName" />
      </a-form-model-item>
      <a-form-model-item label="荣誉类型" prop="honorType" v-bind="formItemLayout" >
        <a-select v-model="form.honorType">
          <a-select-option value="1">荣誉个人</a-select-option>
          <a-select-option value="2">荣誉团体</a-select-option>
          <a-select-option value="3">外部认可</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="获奖频率" prop="frequency" v-bind="formItemLayout" >
        <a-select v-model="form.frequency">
          <a-select-option value="m">月度</a-select-option>
          <a-select-option value="q">季度</a-select-option>
          <a-select-option value="y">年度</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="获奖对象" prop="prizeTarget" v-bind="formItemLayout" >
        <a-textarea placeholder="获奖对象" v-model="form.prizeTarget" :rows="3" />
      </a-form-model-item>
      <a-form-model-item label="年份" prop="year" v-bind="formItemLayout" >
        <a-input-number placeholder="年份" v-model="form.year" :min="2000" :max="2200" />
      </a-form-model-item>
      <a-form-model-item label="奖项描述" prop="prizeDesc" v-bind="formItemLayout" >
        <a-textarea placeholder="奖项描述" v-model="form.prizeDesc" :rows="3" />
      </a-form-model-item>
      <a-form-model-item label="封面图片" prop="coverImage" v-bind="formItemLayout" >
        <UploadImgs :path="IMAGE_HONOR" :limit="1" :file-list="coverImageList" @_change="handleUploadCoverImageChange"></UploadImgs>
      </a-form-model-item>
      <a-form-model-item label="荣誉图片" prop="honorImage" v-bind="formItemLayout" >
        <UploadImgs :path="IMAGE_HONOR" :file-list="honorImageList" @_change="handleUploadHonorImageChange"></UploadImgs>
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import moment from "moment";
import {IMAGE_HONOR} from "../../../utils/upload-path-map"
import UploadImgs from "../../../components/upload-imgs/UploadImgs"

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 }
}

export default {
  name: "HonorAdd",
  components: {UploadImgs},
  props: {
    honorAddVisiable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formItemLayout,
      IMAGE_HONOR,
      coverImageList: [],
      honorImageList: [],
      form: {
        honorType: "",
        frequency: "",
        prizeName: "",
        prizeTarget: "",
        prizeDesc: "",
        year: moment().year(),
        coverImage: undefined,
        honorImage: undefined
      },
      rules: {
        honorType: [
          { required: true, message: '荣誉类型不能为空', trigger: 'change' }
        ],
        frequency: [
          { required: true, message: '获奖频率不能为空', trigger: 'change' }
        ],
        prizeName: [
          { required: true, message: '奖项名称不能为空', trigger: 'blur' }
        ],
        prizeTarget: [
          { required: true, message: '获奖对象不能为空', trigger: 'blur' }
        ],
        coverImage: [
          { required: true, message: '封面图片不能为空', trigger: 'change' }
        ],
        honorImage: [
          { required: true, message: '荣誉图片不能为空', trigger: 'change' }
        ],
      }
    }
  },
  methods: {
    onClose() {
      // this.$refs.ruleForm.resetFields();
      this.reset();
      this.$emit("close");
    },
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
      this.coverImageList = [];
      this.honorImageList = [];
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$post("honor", {...this.form}).then(r => {
            console.log("添加荣誉成功！", r.data);
            this.reset();
            this.$emit("success");
          })
        } else {
          return false;
        }
      });
    },
    handleUploadCoverImageChange(fileList, urls) {
      console.log("上传封面图片组件传过来的值", urls);
      this.form.coverImage = urls.length?urls[0]['filePath']:'';
      this.coverImageList = fileList;
    },
    handleUploadHonorImageChange(fileList, urls) {
      console.log("上传荣誉图片组件传过来的值", urls);
      // this.form.honorImage = JSON.stringify(urls);
      // this.honorImageList = fileList;
      urls = urls.map(item => {return {url: item.filePath}});
      // this.form.honorImage = JSON.stringify(urls);
	  this.form.honorImage = urls[0]?JSON.stringify(urls):'';
      this.honorImageList = fileList;
    },
  }
}
</script>